import axios from "axios";
import qs from "qs";
import md5 from "js-md5";
import API from "./ApiServer";
import { CONTACT_API as service } from "./api";
import { Message } from "element-ui";

import VueCookies from "vue-cookies";
// Vue.use(VueCookies)
// axios.defaults.withCredentials = true

export default (function (global, factory) {
  if (typeof exports === "object" && typeof module !== "undefined") {
    module.exports = factory();
  } else {
    global = global || self;
    return (global.Http = factory());
  }
})(this, function () {
  "use strict";
  let instance = axios.create({
    // baseURL: "http://localhost:9090",
    // baseURL: "http://localhost:8080",
    baseURL: API.apiUrl,
    timeout: 100000,
    // cookie: userId,
    withCredentials: true,
  });

  const Http = {}; //包裹请求方法的容器
  for (let key in service) {
    let api = service[key]; //url method
    //async 作用:避免进入回调地狱
    Http[key] = async function (
      params, //请求参数 get:url put,post,patch(data) delete:url
      // isFormData = false, // 标识是否是 form-data请求
      config = {
        headers: api.defaultHeaders,
      } //配置参数
    ) {
      let newParams = {};
      newParams = params;
      //不同请求判断
      let response = {}; //请求返回值
      if (api.method === "put" || api.method === "post" || api.method === "patch") {
        try {
          response = await instance[api.method](api.url, newParams, config);
        } catch (err) {
          response = err;
        }
      } else if (api.method === "get") {
        // newParams = sign(newParams)
        config.params = newParams;
        try {
          if (!(newParams instanceof Object) && newParams) {
            config.params = {};
            response = await instance[api.method](api.url + "/" + newParams, config);
          } else {
            response = await instance[api.method](api.url, config);
          }
        } catch (err) {
          response = err;
        }
      } else if (api.method === "delete") {
        try {
          response = await instance[api.method](api.url + "/" + newParams, config);
        } catch (err) {
          response = err;
        }
      }
      return response;
    };
  }

  // 添加一个请求拦截器
  instance.interceptors.request.use(
    (config) => {
      if (config.method === "get") {
        if (config.headers["Content-Type"].indexOf("application/x-www-form-urlencoded") === 0) {
          return config;
        }
      }
      if (config.method === "post" || config.method === "put") {
        if (config.headers["Content-Type"].indexOf("application/x-www-form-urlencoded") === 0) {
          config.data = qs.stringify(sign(config.data));
          return config;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 添加一个响应拦截器
  instance.interceptors.response.use(
    (res) => {
      // console.log(res, '响应拦截器', res.data, 'pop')
      let str = res.data;
      // console.log(str, 'resdata', typeof str)
      if (typeof str == "string") {
        if (res.data.includes("MIGfMA0GCSqGSIb3DQEBAQUA  A4GNADCBiQKBgQDLRpG") && res.data.includes("教育云专用浏览器下载")) {
          console.log("跳转");
          let url =
            // "http://kzkt.bjchyedu.cn/uaa/oauth/authorize?response_type=code&client_id=yxyth&redirect_uri=http://kzkt.bjchyedu.cn/jyyth/oauth&scope=ability_read%20cognize_read%20class_read%20grade_read%20knowledge_read%20ordinary_read%20parent_read";
            "https://kzkt.bjchyedu.cn/uaa/oauth/authorize?response_type=code&client_id=lt_admin&redirect_uri=https://kzkt.bjchyedu.cn/jyyth/oauth&scope=ability_read%20cognize_read%20class_read%20grade_read%20knowledge_read%20ordinary_read%20parent_read";
          window.location = url;
          // return
        }
      }

      // if (res.data.status == 200) {
      //     if (res.data.data == '' || res.data.data == null) {
      //         Message({
      //             showClose: true,
      //             message: res.data.message,
      //             type: 'success',
      //             duration: 5000,
      //         })
      //     }
      // }
      // if (res.data.status == 401) {
      //     // return
      //     // Message({
      //     //     showClose: true,
      //     //     message: res.data.message,
      //     //     type: 'warning',
      //     //     duration: 5000,
      //     // })
      // }
      if (res.data.status == 500) {
        Message({
          showClose: true,
          message: res.data.message,
          type: "error",
          duration: 5000,
        });
      }

      if (res.data.code == 400 || res.data.code == 401) {
        Message({
          message: res.data.message,
          type: "warning",
        });
      }

      if (res.data.code == 500) {
        Message({
          message: "数据错误",
          type: "warning",
        });
      }

      if (res.data.status) return res.data;
    },
    (error) => {
      let errorMsg = error.response && error.response.data.message ? error.response.data.message : "服务器异常，请联系管理员!";
      Message({
        message: errorMsg,
        type: "warning",
      });
      return Promise.reject(error);
    }
  );

  return Http;
});

export function sign(params) {
  var str = "";
  if (!params) {
    params = {};
  }
  var token = sessionStorage.getItem("token");
  if (token) {
    params.token = token;
  }
  params.sign = "";
  for (var k in params) {
    if (params[k] === "" || params[k] === "null" || params[k] === null || params[k] === undefined) {
      continue;
    }
    str += k + "=" + params[k] + "&";
  }
  str = str.substring(0, str.length - 1);
  params.sign = getSign(str);
  return objKeySort(params);
}

function objKeySort(obj) {
  var newkey = Object.keys(obj).sort();
  var newObj = {};
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}

function getSign(params, kAppKey, kAppSecret) {
  if (typeof params === "string") {
    return paramsStrSort(params);
  } else if (typeof params === "object") {
    var arr = [];
    for (var i in params) {
      arr.push(i + "=" + params[i]);
    }
    return paramsStrSort(arr.join("&"));
  }
}

function paramsStrSort(paramsStr) {
  var url = paramsStr;
  var urlStr = url.split("&").sort().join("&");
  var newUrl = urlStr + "1b79259b1a3295dd8898e9b227518e56";
  return md5(newUrl);
}
